import request from '@/utils/request'


// 查询新-产品批次详情列表
export function listBatchRecord(query) {
  return request({
    url: '/cigarette/goods-batch-record/list',
    method: 'get',
    params: query
  })
}

// 查询新-产品批次详情分页
export function pageBatchRecord(query) {
  return request({
    url: '/cigarette/goods-batch-record/page',
    method: 'get',
    params: query
  })
}

// 查询新-产品批次详情详细
export function getBatchRecord(data) {
  return request({
    url: '/cigarette/goods-batch-record/detail',
    method: 'get',
    params: data
  })
}

// 新增新-产品批次详情
export function addBatchRecord(data) {
  return request({
    url: '/cigarette/goods-batch-record/add',
    method: 'post',
    data: data
  })
}

// 修改新-产品批次详情
export function updateBatchRecord(data) {
  return request({
    url: '/cigarette/goods-batch-record/edit',
    method: 'post',
    data: data
  })
}

// 删除新-产品批次详情
export function delBatchRecord(data) {
  return request({
    url: '/cigarette/goods-batch-record/delete',
    method: 'post',
    data: data
  })
}
